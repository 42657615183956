header {
	width: 100% !important;
}

.SandPBar {
	width: 100% !important;
}

.error-msg-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	i {
		font-size: 90px;
		color: #ff4d4f;
	}
	&.status-403 {
		i {
			color: #f5e346;
		}
	}
	h1 {
		text-align: center;
		font-size: 28px;
	}
}

.sandbox-container {
	width: 100%;
	.background-part {
		background-image: url('../../images/icons/background-coins.png');
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: cover;
		width: 100%;
		height: 850px;
		display: flex;
		justify-content: space-between;
		position: absolute;
		.background-part-left {
			img {
				width: 300px;
			}
		}
		.background-part-right {
			margin-top: 430px;
			margin-right: 6%;
			img {
				width: 100px;
			}
		}
	}
	.SandPBar.sandbox-profile {
		background-color: #7d6ef1 !important;
		box-shadow: none !important;
		border-bottom: 0px solid #e8e8e8;
		position: relative !important;
		z-index: 2 !important;
		color: white !important;
		// background-color: #7D6EF1 !important;
		height: 100px;
		padding: 0px !important;
		width: 100%;

		.navbarItem {
			padding: 0px !important;
		}

		@media screen and (min-width: 780px) {
			.navbarItem {
				width: 100% !important;

				// display: block !important;
				display: flex !important;
				align-items: center;
				height: 100%;
				.nav_logo_container,
				.nav_tab_container,
				.nav__icons {
					// display: inline-table;
				}

				.nav_menu_icon {
					visibility: hidden;
				}

				.nav_logo_container {
					height: 100px;
					margin-right: 100px;
					.brand {
						position: relative;
						top: 0px;
						height: 100%;
						$size: 50px;
						width: $size !important;
						// background: url('../../images/logo/nugget-logo-white.svg') no-repeat center center;
						// background-size: 80%;
						margin: 0;
						border-radius: 0 !important;
						background: transparent !important;
						&:hover {
							background-color: transparent !important;
						}
					}
				}

				.nav_tab_container {
					height: 100%;
					color: white;
					font-weight: bold;
					.nav_tab_list {
						height: 100%;
						div {
							height: 100%;
						}
					}
				}

				.nav__icons {
					position: absolute;
					right: 0;
					line-height: 100px;
					display: flex;
					top: 5px;
					float: right;
					justify-content: center;
					align-items: center;
					//padding-right: 1vw;

					.create-profile-btn {
						margin-top: 25px;
					}
				}
			}
		}

		@media screen and (min-width: 960px) {
			.navbarItem {
				width: 100%;
				.nav_logo_container {
					height: 100px;
					margin-left: 2vw;
					margin-right: 76px;
				}

				.nav__icons {
					margin-right: 3vw;
					.header-tools {
						margin-right: 10px;
					}
				}
			}
		}

		@media screen and (min-width: 1200px) {
			.navbarItem {
				width: 100%;
				.nav_logo_container {
					height: 100px;
					margin-left: 3vw;
					margin-right: 76px;
				}

				.nav__icons {
					margin-right: 5vw;
					.header-tools {
						margin-right: 10px;
					}
				}
			}
		}

		@media screen and (max-width: 781px) {
			.navbarItem {
				margin-right: 0px !important;
				margin-left: 0px !important;
				padding: 0;
				width: 100%;

				.nav_menu_icon,
				.nav_logo_container {
					display: inline-block;
				}

				.nav_logo_container {
					width: 100%;
					button:nth-child(1) {
						position: absolute;
						top: 23px;
					}
					.brand {
						position: absolute;
						top: 0px;
						height: 100%;
						width: 50%;
						margin: 0;
						border-radius: 0 !important;
						background: transparent !important;
						transform: translateX(-50%);
						left: 50%;
						&:hover {
							background-color: transparent !important;
						}
					}
				}

				.nav_tab_container {
					visibility: hidden;
				}

				.nav__icons {
					visibility: hidden;
				}
			}
		}

		.navbarItem {
			margin: 0 auto;
		}
	}

	.logo-footer {
		padding-top: 30px;
		img {
			width: 35px;
			cursor: pointer;
		}

		span {
			padding-top: 15px;
			color: #999;
		}
	}
}
// .popover_class {
// 	margin-left: -40px;
// 	margin-top: 10px;
// 	.popover-child-div {
// 		width: 185px;
// 		height: 46px;
// 		display: flex;
// 		align-items: center;

// 		.child-button {
// 			font-size: 13px;
// 			justify-content: left;
// 			width: 100%;
// 			color: #9fa9ba;
// 			.child-icon {
// 				width: 18px !important;
// 				height: 15px;
// 				margin-right: 10px;
// 			}
// 		}
// 	}
// }
.centered {
	position: fixed; /* or absolute */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.nav__icons {
	button {
		text-transform: capitalize;
	}
}
