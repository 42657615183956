.fit-modal {
	padding: 15px;
	height: 100%;
	.paper-main {
		overflow-y: hidden;
		position: absolute !important;
		top: 45% !important;
		left: 50% !important;
		transform: translate(-50%, -50%);
		max-height: calc(100vh - 160px);
		min-height: 120px;
		// margin: 0 auto;
		width: calc(100% - 40px);
		max-width: 700px;
		border-radius: 8px;
		box-shadow: 0 15px 30px 0 rgba(141, 151, 200, 0.15);
		background-color: #fff;
		color: #4a4a4a;
		padding-top: 20px;
		@media screen and (max-height: 800px) {
			overflow-y: scroll;
		}
		.fit-title {
			font-size: 20px;
			padding: 10px 35px 10px 35px;
		}

		.fit-content {
			font-size: 16px;
			line-height: 1.6;
			// text-indent: 2em;
			// text-align: justify;
			padding: 0px 35px 28px 35px;
		}
	}
}
