.profile-part {
	background-color: #f6f7f9;

	.left-profile-part {
		.personal-common-info {
			width: 100%;
			padding-top: 70px;
			text-align: center;
			color: #4a4a4a;
			.personal-common-edit-part {
				text-align: right;
				padding-right: 10px;
				svg {
					font-size: 13px;
					color: #7d6ef1;
					cursor: pointer;
					transition: all 0.2s linear;
					&:hover {
						color: #7d6ef1;
						opacity: 0.7;
					}
				}
			}
			.personal-common-avatar {
				width: 120px;
				height: 120px;
				transform: translateX(-50%);
				left: 50%;
			}

			.avatar-container {
				width: 100%;
				height: 120px;
				display: flex;
				justify-content: center;
				.avatar-part {
					position: relative;
					.personal-com-avatar {
						width: 120px;
						height: 120px;
						position: relative;
					}
					.status-img {
						width: 15px;
						height: 15px;
						position: absolute;
						top: 87px;
						right: 2px;
					}
				}
			}

			.personal-fullname {
				padding-top: 15px;
				font-size: 17px;
				letter-spacing: 1px;
			}

			.personal-position {
				padding-top: 5px;
				font-size: 12px;
				letter-spacing: 1px;
			}

			.personal-social {
				padding-top: 20px;
				.social-linkedin {
					fill: #4a90e2;
					padding-right: 10px;
					cursor: pointer;
				}
				.resume-link {
					fill: #8c92f7;
					cursor: pointer;
				}
			}
		}

		.personal-general-info {
			padding-top: 35px;
			.personal-general-label {
				font-size: 12px;
				letter-spacing: 0.71px;
				color: #acacac;
			}

			.personal-general-buttons {
				padding-top: 5px;
				button {
					font-size: 8px;
					color: white;
					background-color: #7d6ef1;
					border-radius: 5px;
					margin-right: 5px;
					margin-bottom: 5px;
				}
			}
		}

		.personal-skills-info {
			padding-top: 25px;
			.personal-skills-label {
				font-size: 12px;
				letter-spacing: 0.71px;
				color: #acacac;
			}

			.personal-skills-buttons {
				padding-top: 5px;
				button {
					font-size: 8px;
					color: white;
					background-color: #7d6ef1;
					border-radius: 5px;
					margin-right: 5px;
					margin-bottom: 5px;
					svg {
						font-size: 12px;
						padding-left: 5px;
					}
				}

				.button-add-new {
					background-color: transparent !important;
					color: #7d6ef1 !important;
					border: 1px dashed #7d6ef1;
				}
			}
		}

		.personal-roles-info {
			padding-top: 25px;
			.personal-roles-label {
				font-size: 12px;
				letter-spacing: 0.71px;
				color: #acacac;
			}

			.personal-roles-buttons {
				padding-top: 5px;
				button {
					font-size: 8px;
					color: white;
					background-color: #7d6ef1;
					border-radius: 5px;
					margin-right: 5px;
					margin-bottom: 5px;
					svg {
						font-size: 12px;
						padding-left: 5px;
					}
				}

				.button-add-new {
					background-color: transparent !important;
					color: #7d6ef1 !important;
					border: 1px dashed #7d6ef1;
				}
			}
		}
	}

	@media screen and (min-width: 781px) {
		.left-profile-part {
			width: 250px;
			margin-left: 54px;
		}
	}

	@media screen and (min-width: 960px) {
		.left-profile-part {
			width: 250px;
			margin-left: calc(2vw + 54px);
		}
	}

	@media screen and (min-width: 1200px) {
		.left-profile-part {
			width: 250px;
			margin-left: calc(3vw + 54px);
		}
	}

	.right-profile-part {
		flex: 1 1 auto;
		.click-comment {
			margin-top: 60px;
			display: none;
			.comment {
				font-size: 16px;
				font-weight: 600;
				color: #4a4a4a;
			}
		}

		.top-tab-part {
			margin-top: 20px;
			display: flex;
			display: none;
			width: 100%;
			.top-tab-item {
				width: 33.3%;
				height: 67px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				border: 1px solid #dfe1e6;
				.img-tab {
					margin-right: 10px;
				}
				.title {
					font-size: 14px;
					font-weight: 500;
					color: #7a869a;
				}
			}
			.top-tab-item:hover,
			.top-tab-item:active {
				background-color: #7773a6;
				.title {
					color: white;
				}
			}
			.top-tab-item:first-of-type {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			.top-tab-item:last-of-type {
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}

		.top-part-container {
			width: 100%;
			margin-top: 70px;
			display: flex;
			.top-rate-part {
				display: flex;
				// display: none;
				// height: 150px;
				height: 100%;
				justify-content: center;
				align-items: center;
				position: relative;
				border-radius: 10px;
				background-color: #fff;
				border: 1px solid #dfe1e6;
				.top-rate-item-p {
					width: 100%;
					max-width: 100%;
					// height: 132px;
					// height: 100%;
					text-align: center;
					display: flex;
					align-items: center;
					.left-category-part {
						position: static !important;
						margin-left: 8px;
						@media (min-width: 900px) {
							margin-left: 12px;
						}
						.content {
							display: block;
							.content-item {
								width: 9px;
								height: 9px;
								margin: 5px 0;
								border-radius: 50%;
								cursor: pointer;
							}
						}
					}
					.main-rate-part {
						width: 100%;
						height: 100%;
						padding: 20px 0;
						.title {
							svg {
								position: absolute;
								// top: 50%;
								top: 20px;
								right: 10px;
								color: #7d6ef1;
								font-size: 16px;
								// transform: translateY(-50%);
								cursor: pointer;
								transition: all 0.2s linear;
								&:hover {
									opacity: 0.7;
								}
							}
							font-size: 14px;
							font-weight: 600;
							letter-spacing: 0.47px;
							line-height: 1;
							margin-top: 16px;
						}
						.rate {
							margin-top: 20px;
							font-weight: 600;
							letter-spacing: 0.83px;

							display: flex;
							justify-content: center;

							button {
								padding: 9px 14px;
								// width: 150px;
								font-family: 'Montserrat';
								font-weight: 500;
								font-size: 13px;
								color: white;
								border-radius: 20px;
								border: none;
							}
						}
						.comment {
							// height: 15px;
							font-size: 14px;
							font-weight: 600;
							line-height: 1.75;
							letter-spacing: 0.27px;
							margin-top: 10px;
						}
						.other-content {
							margin-top: 6px;
							font-size: 25px;
							font-weight: 600;
							letter-spacing: 0.83px;
							color: #4e71a5;
						}
					}
				}
				.top-rate-item-p:first-of-type {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}
				.top-rate-item-p:last-of-type {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}
			}
		}

		.skill-chapter-part {
			.skills-part {
				padding: 20px;
				background-color: #fff;
				border-radius: 10px;
				border: 1px solid #dfe1e6;
				margin-bottom: 17px;
				.title {
					font-size: 16px;
					font-weight: 600;
					line-height: 25px;
					letter-spacing: 0.53px;
					color: #4a4a4a;
					margin-bottom: 25px;
				}

				.chart-containter {
					.skills-container {
						.skills-title {
							font-size: 13px;
							font-weight: 600;
							line-height: 1.92;
							letter-spacing: 0.43px;
						}
						.skills-content {
							.chart-part {
								width: 100%;
							}
						}
					}
				}

				.blank-container {
					width: 100%;
					height: 320px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.chapters-part {
				padding: 20px;
				background-color: #fff;
				border-radius: 10px;
				border: 1px solid #dfe1e6;
				margin-bottom: 17px;

				.title-part {
					display: flex;
					align-items: center;
					margin-bottom: 18px;
					.title {
						margin-left: 10px;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: 0.53px;
						// letter-spacing: 1.79px;
						color: #4a4a4a;
					}
				}

				.sub-title {
					margin-left: 10px;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 0.71px;
				}
			}
		}

		.skills-none-part {
			padding: 20px;
			margin-top: 70px;
			background-color: #fff;
			height: 500px;
			border-radius: 10px;
			border: 1px solid #dfe1e6;
			margin-bottom: 17px;

			.skills-main-container {
				height: 100%;
				.skills-title {
					font-size: 16px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.53px;
					color: #4a4a4a;
				}

				.skills-no-data {
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					width: 100%;
					height: 100%;
					// margin-top: 80px;

					.skills-no-data-img {
						width: 100px;
						height: 112px;
					}
					.no-data-comment {
						text-align: center;
						font-size: 16px;
						line-height: 1.5;
						letter-spacing: 0.53px;
						margin-top: 10px;
						p {
							margin: 5px;
						}
						.underline-comment {
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
			}
		}

		.badge-part {
			padding: 30px 20px 20px 20px;
			margin-bottom: 18px;
			background-color: #fff;
			border-radius: 10px;
			border: 1px solid #dfe1e6;
			.badge-title {
				font-size: 16px;
				font-weight: 600;
				line-height: 1.56;
				letter-spacing: 0.53px;
				color: #4a4a4a;
			}

			.badge-content {
				margin: 54px 0 0 0;
				text-align: center;
				.badge-content-list {
					padding: 0 80px;
					.content-list-container {
						display: flex;
						justify-content: center;
						.badge-content-item {
							cursor: pointer;
							text-align: center;
							margin-bottom: 20px;
							.top-part {
								display: flex;
								justify-content: center;
								margin-bottom: 10px;
								height: 10px;
								.top-part-item {
									width: 10px;
									height: 10px;
									border-radius: 50%;
									margin: 0 5px;
								}
							}
							.badge-item-icon {
								width: 29px;
								height: 37px;
								padding: 15px 18px;
								margin-bottom: 10px;
								font-size: 28px;
								align-self: center;
								margin-left: auto;
								margin-right: auto;
								display: flex;
								align-items: center;
								justify-content: center;
								position: relative;
								overflow: hidden;
								background-repeat: no-repeat;
								background-size: cover;
								-webkit-clip-path: polygon(
									45% 1.33975%,
									46.5798% 0.60307%,
									48.26352% 0.15192%,
									50% 0%,
									51.73648% 0.15192%,
									53.4202% 0.60307%,
									55% 1.33975%,
									89.64102% 21.33975%,
									91.06889% 22.33956%,
									92.30146% 23.57212%,
									93.30127% 25%,
									94.03794% 26.5798%,
									94.48909% 28.26352%,
									94.64102% 30%,
									94.64102% 70%,
									94.48909% 71.73648%,
									94.03794% 73.4202%,
									93.30127% 75%,
									92.30146% 76.42788%,
									91.06889% 77.66044%,
									89.64102% 78.66025%,
									55% 98.66025%,
									53.4202% 99.39693%,
									51.73648% 99.84808%,
									50% 100%,
									48.26352% 99.84808%,
									46.5798% 99.39693%,
									45% 98.66025%,
									10.35898% 78.66025%,
									8.93111% 77.66044%,
									7.69854% 76.42788%,
									6.69873% 75%,
									5.96206% 73.4202%,
									5.51091% 71.73648%,
									5.35898% 70%,
									5.35898% 30%,
									5.51091% 28.26352%,
									5.96206% 26.5798%,
									6.69873% 25%,
									7.69854% 23.57212%,
									8.93111% 22.33956%,
									10.35898% 21.33975%
								);
								clip-path: polygon(
									45% 1.33975%,
									46.5798% 0.60307%,
									48.26352% 0.15192%,
									50% 0%,
									51.73648% 0.15192%,
									53.4202% 0.60307%,
									55% 1.33975%,
									89.64102% 21.33975%,
									91.06889% 22.33956%,
									92.30146% 23.57212%,
									93.30127% 25%,
									94.03794% 26.5798%,
									94.48909% 28.26352%,
									94.64102% 30%,
									94.64102% 70%,
									94.48909% 71.73648%,
									94.03794% 73.4202%,
									93.30127% 75%,
									92.30146% 76.42788%,
									91.06889% 77.66044%,
									89.64102% 78.66025%,
									55% 98.66025%,
									53.4202% 99.39693%,
									51.73648% 99.84808%,
									50% 100%,
									48.26352% 99.84808%,
									46.5798% 99.39693%,
									45% 98.66025%,
									10.35898% 78.66025%,
									8.93111% 77.66044%,
									7.69854% 76.42788%,
									6.69873% 75%,
									5.96206% 73.4202%,
									5.51091% 71.73648%,
									5.35898% 70%,
									5.35898% 30%,
									5.51091% 28.26352%,
									5.96206% 26.5798%,
									6.69873% 25%,
									7.69854% 23.57212%,
									8.93111% 22.33956%,
									10.35898% 21.33975%
								);
								&.earned {
									&:after {
										content: '';
										position: absolute;
										top: -110%;
										left: -210%;
										width: 200%;
										height: 200%;
										opacity: 0;
										transform: rotate(30deg);

										background: rgba(255, 255, 255, 0.13);
										background: linear-gradient(
											to right,
											rgba(255, 255, 255, 0.13) 0%,
											rgba(255, 255, 255, 0.13) 77%,
											rgba(255, 255, 255, 0.5) 92%,
											rgba(255, 255, 255, 0) 100%
										);
									}
									&:hover:after {
										opacity: 1;
										top: -30%;
										left: -30%;
										transition-property: left, top, opacity;
										transition-duration: 0.7s, 0.7s, 0.15s;
										transition-timing-function: ease;
									}
									&:active:after {
										opacity: 0;
									}
								}
							}
							font-size: 12px;
							letter-spacing: 0.71px;
							color: #9fa9ba;
						}
					}
				}

				@media screen and (max-width: 991px) {
					.badge-content-list {
						padding: 0 20px;
					}
				}

				@media screen and (max-width: 767px) {
					.badge-content-list {
						padding: 0 10px;
						.content-list-container {
							justify-content: unset !important;
						}
					}
				}

				.badge-content-comment {
					margin: 50px 0 0 0;
					display: flex;
					justify-content: center;
					font-size: 16px;
					line-height: 1.94;
					letter-spacing: 0.53px;
					.comment-em {
						max-width: 800px;
					}
				}
			}
		}

		.submissions-container {
			padding: 30px 20px 20px 20px;
			background-color: #fff;
			display: flex;
			margin-bottom: 100px;
			border-radius: 10px;
			border: 1px solid #dfe1e6;
			justify-content: space-between;

			.main-container {
				.title {
					font-size: 16px;
					font-weight: 600;
					line-height: 1.56;
					letter-spacing: 0.53px;
					color: #4a4a4a;
					margin-bottom: 27px;
				}
				.year-title {
					font-size: 12px;
					font-weight: 600;
					letter-spacing: 0.71px;
					columns: #4a4a4a;
					padding-bottom: 12px;
				}
				.year-calendar-container {
					.year-calendar-child {
						display: flex;
						justify-content: center;
						.year-calendar-item {
							width: 100px;
							margin-bottom: 20px;
							.item-month-part {
								height: 80px;
							}
							.item-month-name {
								font-size: 10px;
								font-weight: 500;
								letter-spacing: 0.59px;
								columns: #9b9b9b;
								text-align: center;
							}
						}
					}
				}
				.service-rate {
					display: flex;
					margin-top: 20px;

					div {
						width: 10px;
						height: 10px;
						border-radius: 1px;
						margin-right: 6px;
					}
					.rate-1 {
						background-color: #c7e7a3;
					}
					.rate-2 {
						background-color: #7db142;
					}
					.rate-3 {
						background-color: #417505;
					}
				}
				.service-list {
					margin-top: 32px;
					// display: none;
					.service-list-title {
						font-size: 13px;
						font-weight: 500;
						line-height: 1.92;
						letter-spacing: 0.43px;
						color: #4a4a4a;
						margin-bottom: 17px;
					}
					.service-item {
						margin-bottom: 18px;
						.title {
							font-size: 12px;
							letter-spacing: 0.71px;
							color: #4a90e2;
							margin-bottom: 4px;
						}
						.date {
							font-size: 10px;
							letter-spacing: 0.59px;
							color: #9b9b9b;
						}
					}
					.service-more {
						font-size: 10px;
						letter-spacing: 0.59px;
						color: #4a4a4a;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.color-scale-1 {
	fill: #c7e7a3;
}
.color-scale-2 {
	fill: #7db142;
}
.color-scale-3 {
	fill: #417505;
}

@media screen and (min-width: 1500px) {
	.profile-part {
		display: flex;

		.right-profile-part {
			margin-left: 20px;
			margin-right: 4%;
		}
	}
}

@media screen and (max-width: 1500px) {
	.profile-part {
		display: flex;

		.right-profile-part {
			margin-left: 20px;
			margin-right: 3%;
		}
	}
}

@media screen and (max-width: 780px) {
	.profile-part {
		display: flex;
		flex-direction: column;
		.left-profile-part {
			width: 100%;
			.personal-general-info {
				padding: 5px 10px;
				.personal-general-label {
					display: none;
				}

				.personal-general-buttons {
					text-align: center;
				}
			}

			.personal-skills-info {
				padding: 5px 10px;
				.personal-skills-label {
					display: none;
				}
				.personal-skills-buttons {
					text-align: center;
				}
			}

			.personal-roles-info {
				padding: 5px 10px;
				.personal-roles-label {
					display: none;
				}
				.personal-roles-buttons {
					text-align: center;
				}
			}
		}
		.right-profile-part {
			margin: 0px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
