.badge-modal {
	padding: 15px;
	// height: 100%;
	.paper-main {
		margin: 0 auto;
		width: calc(100% - 40px);
		max-width: 700px;
		border-radius: 8px;
		padding-bottom: 30px;
		box-shadow: 0 15px 30px 0 rgba(141, 151, 200, 0.15);
		background-color: #fff;
		height: auto;
		color: #4a4a4a;
		@media (max-width: 500px) {
			top: 50% !important;
		}
		.divider {
			width: calc(100% + 70px);
			margin-left: -35px;
			// margin-left: auto;
			// margin-right: auto;
		}
		.paper-container {
			padding-left: 35px;
			padding-right: 35px;
		}
		.badge-title {
			font-size: 26px;
			padding: 28px 0 28px;
		}

		.badge-content {
			font-size: 14px;
			padding: 42px 0 20px;
		}
		.badge-categories {
			.category-item {
				.category-container {
					display: flex;
					align-items: center;
					span {
						&.category-color {
							display: block;
							width: 10px;
							height: 10px;
							border-radius: 2px;
							margin-right: 10px;
						}
						&:not(.category-color) {
							font-size: 1rem;
						}
					}
				}
				small {
					display: block;
					margin-top: 5px;
					color: #9fa9ba;
				}
			}
		}
		.badge-content-item {
			cursor: pointer;
			text-align: center;
			margin-top: 5px;
			margin-bottom: 40px;
			.top-part {
				display: flex;
				justify-content: center;
				margin-bottom: 10px;
				height: 10px;
				.top-part-item {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin: 0 5px;
				}
			}

			.badge-item-icon {
				width: 39px;
				height: 46px;
				padding: 15px 18px;
				margin-bottom: 10px;
				font-size: 32px;
				align-self: center;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				overflow: hidden;
				background-repeat: no-repeat;
				background-size: cover;
				-webkit-clip-path: polygon(
					45% 1.33975%,
					46.5798% 0.60307%,
					48.26352% 0.15192%,
					50% 0%,
					51.73648% 0.15192%,
					53.4202% 0.60307%,
					55% 1.33975%,
					89.64102% 21.33975%,
					91.06889% 22.33956%,
					92.30146% 23.57212%,
					93.30127% 25%,
					94.03794% 26.5798%,
					94.48909% 28.26352%,
					94.64102% 30%,
					94.64102% 70%,
					94.48909% 71.73648%,
					94.03794% 73.4202%,
					93.30127% 75%,
					92.30146% 76.42788%,
					91.06889% 77.66044%,
					89.64102% 78.66025%,
					55% 98.66025%,
					53.4202% 99.39693%,
					51.73648% 99.84808%,
					50% 100%,
					48.26352% 99.84808%,
					46.5798% 99.39693%,
					45% 98.66025%,
					10.35898% 78.66025%,
					8.93111% 77.66044%,
					7.69854% 76.42788%,
					6.69873% 75%,
					5.96206% 73.4202%,
					5.51091% 71.73648%,
					5.35898% 70%,
					5.35898% 30%,
					5.51091% 28.26352%,
					5.96206% 26.5798%,
					6.69873% 25%,
					7.69854% 23.57212%,
					8.93111% 22.33956%,
					10.35898% 21.33975%
				);
				clip-path: polygon(
					45% 1.33975%,
					46.5798% 0.60307%,
					48.26352% 0.15192%,
					50% 0%,
					51.73648% 0.15192%,
					53.4202% 0.60307%,
					55% 1.33975%,
					89.64102% 21.33975%,
					91.06889% 22.33956%,
					92.30146% 23.57212%,
					93.30127% 25%,
					94.03794% 26.5798%,
					94.48909% 28.26352%,
					94.64102% 30%,
					94.64102% 70%,
					94.48909% 71.73648%,
					94.03794% 73.4202%,
					93.30127% 75%,
					92.30146% 76.42788%,
					91.06889% 77.66044%,
					89.64102% 78.66025%,
					55% 98.66025%,
					53.4202% 99.39693%,
					51.73648% 99.84808%,
					50% 100%,
					48.26352% 99.84808%,
					46.5798% 99.39693%,
					45% 98.66025%,
					10.35898% 78.66025%,
					8.93111% 77.66044%,
					7.69854% 76.42788%,
					6.69873% 75%,
					5.96206% 73.4202%,
					5.51091% 71.73648%,
					5.35898% 70%,
					5.35898% 30%,
					5.51091% 28.26352%,
					5.96206% 26.5798%,
					6.69873% 25%,
					7.69854% 23.57212%,
					8.93111% 22.33956%,
					10.35898% 21.33975%
				);
				&.earned {
					&:after {
						content: '';
						position: absolute;
						top: -110%;
						left: -210%;
						width: 200%;
						height: 200%;
						opacity: 0;
						transform: rotate(30deg);

						background: rgba(255, 255, 255, 0.13);
						background: linear-gradient(
							to right,
							rgba(255, 255, 255, 0.13) 0%,
							rgba(255, 255, 255, 0.13) 77%,
							rgba(255, 255, 255, 0.5) 92%,
							rgba(255, 255, 255, 0) 100%
						);
					}
					&:hover:after {
						opacity: 1;
						top: -30%;
						left: -30%;
						transition-property: left, top, opacity;
						transition-duration: 0.7s, 0.7s, 0.15s;
						transition-timing-function: ease;
					}
					&:active:after {
						opacity: 0;
					}
				}
			}
			font-size: 12px;
			letter-spacing: 0.71px;
			color: #9fa9ba;
		}
	}
}
