.title-x {
	margin-top: 20px;
	padding: 12px;
}
.polar-label-wrapper {
	margin: 30px auto;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
	.polar-label {
		cursor: pointer;
		margin-bottom: 10px;
		margin-right: 7px;
		flex-basis: 12%;
		margin: 0 auto 10px 10px;
		background-color: #fff;
		border-radius: 5px;
		//border: solid 1.8px #8c92f7;
		//border-radius: 19.8px;
		//height: 40px;
		//color: #777ccd;
		font-size: 13.2px;
		font-weight: 500;
		line-height: 1.67;
		letter-spacing: 0.4px;
		text-transform: capitalize;
	}
}
.polar-label-pane {
	margin-top: 80px;
}

.btn-see-more {
	margin: 20px;
	color: blue;
}

.bar-label {
	width: 150px;
	white-space: normal;
}

@media screen and (max-width: 767px) {
	.bar-label {
		width: 80px;
		white-space: normal;
	}
}

.highcharts-xaxis-labels {
	span {
		left: 10px !important;
	}
}
