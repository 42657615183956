.stepper-container {
	.icon-color {
		color: #9b9b9b;
	}
	.text-color {
		color: #9b9b9b;
		fill: #9b9b9b;
	}
	.step-item {
		.step-title {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.71px;
			// color: rgba(37, 56, 88, 0.75);
			cursor: pointer;
		}
		&.public {
			.step-title {
				cursor: default;
			}
		}
	}
	.step-comment {
		font-size: 11px;
		font-weight: 500;
		line-height: 2;
		color: #9b9b9b;
	}

	div {
		padding: 3px 0 !important;
	}
}
